import React from "react";
import lodash from "lodash";
import moment from "moment";
// import momentDurationFormatSetup from "moment-duration-format";

// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // Label,
  // FormGroup,
  // Input,
  Table,
  Row,
  Col,
  // UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";
import api from "helpers/api";

import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

import { trackPromise } from "react-promise-tracker";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          top: "calc(25%)",
          display: "flex",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          transition: "opacity 0.3s",

          zIndex: "99999999999",
          opacity: ".8",
          // backgroundColor: "#eaeaea"
        }}
      >
        <Loader type="Oval" color="#1d8cf8" height={100} width={100} />
      </div>
    )
  );
};

const getPreviousMonth = (no_of_months) => {
  const reducedmonth = moment().subtract(no_of_months, "months").format("MMMM");
  return reducedmonth;
};

export const getHoursPerMonth = (eventsPerMonth) => {
  const hoursTracked = eventsPerMonth.reduce((acc, currentValue) => {
    const start_time = moment(currentValue.time_schedule.start_time);
    const end_time = moment(currentValue.time_schedule.end_time);

    return moment.duration(end_time.diff(start_time)).asHours() + acc;
  }, 0);

  return hoursTracked;
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: "data1",
      groupByMonth: {},
      groupUsersByMonth: {},
      datavalues: [],
      storeAllUsers: [],
      storeAllEvents: [],
      alltimezones: [],
      alltimezonevalues: [],
      totaleventsvalue: [],
      latestperfivemonths: [],
      totaltrackedvalue: 0,
      usersbyjoindate: [],
    };
    this.performancedata = {
      data1: (canvas) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

        return {
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
          datasets: [
            {
              label: "Total",
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: "#1f8ef1",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#1f8ef1",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#1f8ef1",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: this.state.datavalues,
            },
          ],
        };
      },
    };

    this.usersByTimeZone = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
        gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
        gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

        return {
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
          datasets: [
            {
              label: "Users",
              fill: true,
              backgroundColor: gradientStroke,
              hoverBackgroundColor: gradientStroke,
              borderColor: "#d048b6",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: this.state.usersbyjoindate,
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(225,78,202,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                suggestedMin: 60,
                suggestedMax: 120,
                padding: 20,
                fontColor: "#9e9e9e",
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(225,78,202,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e",
              },
            },
          ],
        },
      },
    };

    this.totalEvents = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

        return {
          labels: [
            getPreviousMonth(5).substring(0, 3),
            getPreviousMonth(4).substring(0, 3),
            getPreviousMonth(3).substring(0, 3),
            getPreviousMonth(2).substring(0, 3),
            getPreviousMonth(1).substring(0, 3),
            getPreviousMonth(0).substring(0, 3),
          ],
          datasets: [
            {
              label: "Events Created",
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: "#1f8ef1",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#1f8ef1",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#1f8ef1",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: this.state.totaleventsvalue,
            },
          ],
        };
      },
    };

    this.trackedhours = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
        gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
        gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

        return {
          labels: [
            getPreviousMonth(4).substring(0, 3),
            getPreviousMonth(3).substring(0, 3),
            getPreviousMonth(2).substring(0, 3),
            getPreviousMonth(1).substring(0, 3),
            getPreviousMonth(0).substring(0, 3),
          ],
          datasets: [
            {
              label: "Hours Tracked",
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: "#00d6b4",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#00d6b4",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#00d6b4",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: this.state.latestperfivemonths,
            },
          ],
        };
      },
    };
  }

  setBgChartData = (name) => {
    this.setState({
      bigChartData: name,
    });

    switch (name) {
      case "data1":
        this.getTotalEvents();
        break;
      case "data2":
        this.getHoursTracked();
        break;
      case "data3":
        this.getUsers();
        break;
      default:
        break;
    }
  };

  getEventsForLastFiveMonths(data) {
    const a = data[getPreviousMonth(0)] ? data[getPreviousMonth(0)].length : 0;
    const b = data[getPreviousMonth(1)] ? data[getPreviousMonth(1)].length : 0;
    const c = data[getPreviousMonth(2)] ? data[getPreviousMonth(2)].length : 0;
    const d = data[getPreviousMonth(3)] ? data[getPreviousMonth(3)].length : 0;
    const e = data[getPreviousMonth(4)] ? data[getPreviousMonth(4)].length : 0;
    const f = data[getPreviousMonth(5)] ? data[getPreviousMonth(5)].length : 0;
    const result = [f, e, d, c, b, a];
    this.setState({ totaleventsvalue: result });
    // console.log(result);
  }

  getHoursTrackedForLastFiveMonths(data) {
    const a = data[getPreviousMonth(0)]
      ? Math.ceil(getHoursPerMonth(data[getPreviousMonth(0)]))
      : 0;
    const b = data[getPreviousMonth(1)]
      ? Math.ceil(getHoursPerMonth(data[getPreviousMonth(1)]))
      : 0;
    const c = data[getPreviousMonth(2)]
      ? Math.ceil(getHoursPerMonth(data[getPreviousMonth(2)]))
      : 0;
    const d = data[getPreviousMonth(3)]
      ? Math.ceil(getHoursPerMonth(data[getPreviousMonth(3)]))
      : 0;
    const e = data[getPreviousMonth(4)]
      ? Math.ceil(getHoursPerMonth(data[getPreviousMonth(4)]))
      : 0;
    const result = [e, d, c, b, a];
    this.setState({ latestperfivemonths: result });
    // console.log(result);
  }

  getTotalEvents(data = this.state.groupByMonth) {
    const result = [];
    result.push(data.January ? data.January.length : 0);
    result.push(data.February ? data.February.length : 0);
    result.push(data.March ? data.March.length : 0);
    result.push(data.April ? data.April.length : 0);
    result.push(data.May ? data.May.length : 0);
    result.push(data.June ? data.June.length : 0);
    result.push(data.July ? data.July.length : 0);
    result.push(data.August ? data.August.length : 0);
    result.push(data.September ? data.September.length : 0);
    result.push(data.October ? data.October.length : 0);
    result.push(data.November ? data.November.length : 0);
    result.push(data.December ? data.December.length : 0);
    this.setState({ datavalues: result });
  }

  getHoursTracked(data = this.state.groupByMonth, params) {
    const result = [];
    result.push(data.January ? getHoursPerMonth(data.January) : 0);
    result.push(data.February ? getHoursPerMonth(data.February) : 0);
    result.push(data.March ? getHoursPerMonth(data.March) : 0);
    result.push(data.April ? getHoursPerMonth(data.April) : 0);
    result.push(data.May ? getHoursPerMonth(data.May) : 0);
    result.push(data.June ? getHoursPerMonth(data.June) : 0);
    result.push(data.July ? getHoursPerMonth(data.July) : 0);
    result.push(data.August ? getHoursPerMonth(data.August) : 0);
    result.push(data.September ? getHoursPerMonth(data.September) : 0);
    result.push(data.October ? getHoursPerMonth(data.October) : 0);
    result.push(data.November ? getHoursPerMonth(data.November) : 0);
    result.push(data.December ? getHoursPerMonth(data.December) : 0);
    // console.log(result);
    if (!params) {
      this.setState({ datavalues: result });
    }
    const totaltrackedvalue = result.reduce(
      (acc, currentValue) => acc + currentValue
    );

    this.setState({ totaltrackedvalue });
  }

  getUsers(data = this.state.groupUsersByMonth, barchat = null) {
    const result = [];
    result.push(data.January ? data.January.length : 0);
    result.push(data.February ? data.February.length : 0);
    result.push(data.March ? data.March.length : 0);
    result.push(data.April ? data.April.length : 0);
    result.push(data.May ? data.May.length : 0);
    result.push(data.June ? data.June.length : 0);
    result.push(data.July ? data.July.length : 0);
    result.push(data.August ? data.August.length : 0);
    result.push(data.September ? data.September.length : 0);
    result.push(data.October ? data.October.length : 0);
    result.push(data.November ? data.November.length : 0);
    result.push(data.December ? data.December.length : 0);
    if (!barchat) {
      this.setState({ datavalues: result });
    }
    if (barchat) {
      this.setState({ usersbyjoindate: result });
    }
  }

  async componentDidMount() {
    const [fetchEvents, fetchUsers] = await Promise.all([
      trackPromise(api.get("api/v1/admin/fetch-all-events").getAll()),
      trackPromise(api.get("api/v1/admin/fetch-all-users").getAll()),
    ]);

    if (fetchEvents.status === 200) {
      if (fetchEvents.data.status === "success") {
        this.setState({ storeAllEvents: fetchEvents.data.data });
        const groupByMonth = lodash.groupBy(fetchEvents.data.data, function (
          value
        ) {
          return moment(value.time_schedule.start_time).format("MMMM");
        });
        this.setState({ groupByMonth });
        this.getTotalEvents(groupByMonth);
        this.getEventsForLastFiveMonths(groupByMonth);
        this.getHoursTrackedForLastFiveMonths(groupByMonth);
        this.getHoursTracked(groupByMonth, { shouldNotSetState: true });
        // console.log(groupByMonth);
      }
    }
    if (fetchUsers.status === 200) {
      if (fetchUsers.data.status === "success") {
        this.setState({ storeAllUsers: fetchUsers.data.data });
        const groupByTimezone = lodash.groupBy(fetchUsers.data.data, function (
          value
        ) {
          return value.notification_settings.user_timezone !== ""
            ? value.notification_settings.user_timezone
            : "Other";
        });
        const timezonevalues = [];
        Object.values(groupByTimezone).forEach((item) =>
          timezonevalues.push(item.length)
        );
        const groupByMonth = lodash.groupBy(fetchUsers.data.data, function (
          value
        ) {
          return moment(value.join_date).format("MMMM");
        });
        var barchat = true;
        this.getUsers(groupByMonth, barchat);
        this.setState({
          alltimezones: Object.keys(groupByTimezone),
          timezonevalues,
          groupUsersByMonth: groupByMonth,
        });
      }
    }
  }

  calculateTotalEventsPerUser(uuid) {
    const len = this.state.storeAllEvents.filter(
      (event) => event.uuid === uuid
    );
    return len.length;
  }

  calculateTrackedHoursPerUser(uuid) {
    const user_events = this.state.storeAllEvents.filter(
      (event) => event.uuid === uuid
    );
    return getHoursPerMonth(user_events);
  }

  calculateDaysUsedPerUser(uuid) {
    const user_events = this.state.storeAllEvents.filter(
      (event) => event.uuid === uuid
    );
    const groupedDays = lodash.groupBy(user_events, function (value) {
      return moment(value.time_schedule.start_time).format("YYYY, MM, DD");
    });
    // console.log(lodash.size(groupedDays));
    return lodash.size(groupedDays);
  }

  calculateActiveWeeksPerUser = (uuid) => {
    const user_events = this.state.storeAllEvents.filter(
      (event) => event.uuid === uuid
    );
    const groupedWeek = lodash.groupBy(user_events, function (value) {
      return moment(value.time_schedule.start_time).format("YYYY, ww");
    });
    // console.log(lodash.size(groupedWeek));
    return lodash.size(groupedWeek);
  };

  calculateAverageActiveDays(uuid, join_date) {
    // const today = moment();
    // const joinedDate = moment(join_date);
    // const getWeeks = today.diff(joinedDate, "weeks");
    // const result = Math.ceil(
    //   this.calculateDaysUsedPerUser(uuid) /
    //     this.calculateActiveWeeksPerUser(uuid)
    // );

    const result = Math.ceil(
      this.calculateTotalEventsPerUser(uuid) /
        this.calculateDaysUsedPerUser(uuid)
    );

    // console.log(this.calculateActiveWeeksPerUser(uuid));
    if (this.calculateDaysUsedPerUser(uuid) === 0) {
      return 0;
    }
    return result;
  }

  render() {
    return (
      <>
        <LoadingIndicator />
        <div className="content">
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <h5 className="card-category">Total Events Created </h5>
                      <CardTitle tag="h2">Performance</CardTitle>
                    </Col>
                    <Col sm="6">
                      <ButtonGroup
                        className="btn-group-toggle float-right"
                        data-toggle="buttons"
                      >
                        <Button
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data1",
                          })}
                          color="info"
                          id="0"
                          size="sm"
                          onClick={() => this.setBgChartData("data1")}
                        >
                          <input
                            defaultChecked
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Total Events
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="1"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data2",
                          })}
                          onClick={() => this.setBgChartData("data2")}
                        >
                          <input
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Hours Tracked
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="2"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data3",
                          })}
                          onClick={() => this.setBgChartData("data3")}
                        >
                          <input
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Users
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={this.performancedata.data1}
                      options={chartExample1.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Total Events Created</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-time-alarm text-info" />
                    {this.state.storeAllEvents.length}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={this.totalEvents.data}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Users</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-single-02 text-primary" />{" "}
                    {this.state.storeAllUsers.length}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Bar
                      data={this.usersByTimeZone.data}
                      options={chartExample3.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Hours Tracked</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-send text-success" />
                    {moment
                      .duration(this.state.totaltrackedvalue, "hours")
                      .format("h [hrs]  m [min]")}
                    {/* {this.state.totaltrackedvalue} hrs */}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={this.trackedhours.data}
                      options={chartExample4.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    User Activities (Top 10)
                    <div className="float-right pull-right">
                      <a href="/admin/users">
                        <Button
                          tag="label"
                          className="btn-simple"
                          color="info"
                          size="md"
                        >
                          View All
                        </Button>
                      </a>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table
                    className="tablesorter"
                    responsive
                    hover
                    size="sm"
                    striped
                  >
                    <thead className="text-primary">
                      <tr>
                        <th>
                          <i className="tim-icons icon-zoom-split"></i>uuid
                        </th>
                        <th>Join Date</th>
                        <th>Total Events </th>
                        <th>
                          <i className="tim-icons icon-time-alarm"></i> Hours
                          Tracked
                        </th>
                        <th>Avg Entries/Active Day </th>
                        <th>Days Used</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.storeAllUsers
                        .sort(
                          (a, b) =>
                            this.calculateTotalEventsPerUser(b.uuid) -
                            this.calculateTotalEventsPerUser(a.uuid)
                        )
                        .slice(0, 10)
                        .map((item) => (
                          <tr key={item.uuid}>
                            <td>{item.uuid}</td>
                            <td>
                              {moment(item.join_date).format("DD MMM, YYYY")}
                            </td>
                            <td>
                              {this.calculateTotalEventsPerUser(item.uuid)}
                            </td>
                            <td>
                              {moment
                                .duration(
                                  this.calculateTrackedHoursPerUser(item.uuid),
                                  "hours"
                                )
                                .format("h [hrs]  m [min]")}
                            </td>
                            <td>
                              {this.calculateAverageActiveDays(
                                item.uuid,
                                item.join_date
                              )}
                            </td>
                            <td>{this.calculateDaysUsedPerUser(item.uuid)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
