import React, { Component } from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  // Table
  // CardFooter,
  // CardText,
  // Button,
  // FormGroup,
  // Form,
  // Input,
} from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "../assets/css/single-user.css";

const localizer = momentLocalizer(moment);

// const ColoredDateCellWrapper = ({ children }) =>
//   React.cloneElement(React.Children.only(children), {
//     style: {
//       backgroundColor: "lightblue",
//     },
//   });

export default class SingleUserEvents extends Component {
  state = {
    userEvents: [],
  };
  componentDidMount() {
    const { userEvents } = this.props.location.state;
    console.log(this.props);
    const allEvents = [];
    userEvents.forEach((event) => {
      const obj = {};
      obj["start"] = moment(event.time_schedule.start_time).toDate();
      obj["end"] = moment(event.time_schedule.end_time).toDate();
      allEvents.push(obj);
    });
    this.setState({ userEvents: allEvents });
  }
  render() {
    const { id } = this.props.match.params;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">User Activity</h5>
                <h3>UUID: {id}</h3>
              </CardHeader>

              <CardBody>
                <Calendar
                  localizer={localizer}
                  defaultDate={new Date()}
                  defaultView="month"
                  events={this.state.userEvents}
                  style={{ height: "80vh" }}
                  // components={{
                  //   timeSlotWrapper: ColoredDateCellWrapper,
                  // }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
