/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin for creating notifications over the dashboard
// import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col
  // Alert,
  // UncontrolledAlert,
  // Button,
  // CardHeader,
  // CardTitle,
} from "reactstrap";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
import api from "helpers/api";

import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

import { trackPromise } from "react-promise-tracker";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          top: "calc(25%)",
          display: "flex",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          transition: "opacity 0.3s",

          zIndex: "99999999999",
          opacity: ".8"
          // backgroundColor: "#eaeaea"
        }}
      >
        <Loader type="Oval" color="#1d8cf8" height={100} width={100} />
      </div>
    )
  );
};

const convertCodeToString = (str) => {
  if (str) {
    return str.split("_").join(" ");
  }
  return "";
};

class Notifications extends React.Component {
  state = {
    storeAllReminders: []
  };

  formatTableData(data) {
    const storeAllReminders = [];
    data.forEach((reminder) => {
      const reminderObject = {
        uuid: reminder.uuid,
        // event_title: reminder.event_title,
        reminder_time: moment(reminder.reminder_time).format(
          "YYYY/MM/DD hh:mm"
        ),
        alert_time: convertCodeToString(reminder.alert_time_code)
      };
      storeAllReminders.push(reminderObject);
    });

    this.setState({ storeAllReminders });
  }

  async componentDidMount() {
    const fetchReminders = await trackPromise(
      api.get("api/v1/admin/fetch-all-reminders").getAllReminders()
    );
    if (fetchReminders.status === 200) {
      if (fetchReminders.data.status === "success") {
        // console.log(fetchReminders.data.data);
        this.formatTableData(fetchReminders.data.data);
      }
    }
  }
  render() {
    return (
      <>
        <LoadingIndicator />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                {/* <CardHeader>
                  <CardTitle tag="h4">Notifications Style</CardTitle>
                </CardHeader> */}
                <CardBody>
                  <CardBody>
                    <MDBDataTable
                      // striped
                      // bordered
                      noBottomColumns
                      hover
                      data={{
                        columns: [
                          {
                            label: "uuid",
                            field: "uuid",
                            // sort: "asc",
                            width: 200
                          },
                          // {
                          //   label: "Event Title",
                          //   field: "event_title",
                          //   // sort: "asc",
                          //   width: 150,
                          // },
                          {
                            label: "Reminder Time",
                            field: "reminder_time",
                            sort: "desc",
                            width: 270
                          },
                          {
                            label: "Alert Time",
                            field: "alert_time",
                            // sort: "asc",
                            width: 200
                          }
                        ],
                        rows: this.state.storeAllReminders
                      }}
                    />
                  </CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Notifications;
