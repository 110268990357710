import React, { Component } from "react";
import moment from "moment";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import api from "helpers/api";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import { MDBDataTable } from "mdbreact";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          top: "calc(25%)",
          display: "flex",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          transition: "opacity 0.3s",

          zIndex: "99999999999",
          opacity: ".8"
          // backgroundColor: "#eaeaea"
        }}
      >
        <Loader type="Oval" color="#1d8cf8" height={100} width={100} />
      </div>
    )
  );
};

export default class Lifescore extends Component {
  state = {
    storeAllLifescores: []
  };

  customizeData(data) {
    var parseddata = [];
    data.forEach((e) => {
      const start_of_week = moment(e.weekInterval.startOfWeek).format(
        "YYYY,MMMM, DD"
      );
      const end_of_week = moment(e.weekInterval.endOfWeek).format(
        "YYYY,MMMM, DD"
      );

      const param = {
        start_of_week,
        end_of_week,
        uuid: e.uuid,
        lifescore: e.lifescore
      };
      parseddata.push(param);
      //   return param;
    });
    this.setState({ storeAllLifescores: parseddata });
  }

  async componentDidMount() {
    const allLifescores = await trackPromise(api.get().getAllLifescores());
    if (allLifescores.status === 200) {
      console.log(allLifescores);
      if (allLifescores.data.status === "success") {
        this.customizeData(allLifescores.data.data);
      }
    }
  }
  render() {
    return (
      <>
        <LoadingIndicator />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">User Activities</h5>
                </CardHeader>

                <CardBody>
                  <MDBDataTable
                    striped
                    // bordered
                    hover
                    noBottomColumns
                    data={{
                      columns: [
                        {
                          label: "uuid",
                          field: "uuid",
                          // sort: "asc",
                          width: 150
                        },
                        {
                          label: "Start of Week",
                          field: "start_of_week",
                          sort: "desc",
                          width: 150
                        },
                        {
                          label: "End of Week",
                          field: "end_of_week",
                          sort: "asc",
                          width: 200
                        },
                        {
                          label: "Lifescore",
                          field: "lifescore",
                          sort: "asc",
                          width: 100
                        }
                      ],
                      rows: this.state.storeAllLifescores
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
