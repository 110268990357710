// import React from "react";
import axios from "axios";

//development
// const baseUri = "http://localhost:5000";
// const baseUri = "https://api.lifechitect.app";

//production
const baseUri = "https://api-prod.lifechitect.app";

export default {
  get(url) {
    return {
      getAll: () => axios.get(`${baseUri}/${url}`),
      getAllUsers: () => axios.get(`${baseUri}/${url}`),
      getAllReminders: () => axios.get(`${baseUri}/${url}`),
      getAllFeedbacks: () =>
        axios.get(`${baseUri}/api/v1/admin/fetch-all-user-feedbacks`),
      getAllLifescores: () =>
        axios.get(`${baseUri}/api/v1/admin/fetch-all-lifescores`),
    };
  },
};
