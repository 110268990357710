import React, { Component, useContext } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import { Auth0Context } from "./contexts/auth0-context"; // <-- new

import { Button, ButtonGroup } from "reactstrap";

const hist = createBrowserHistory();

const App = () => {
  const { isLoading, isAuthenticated, user, loginWithRedirect } = useContext(
    Auth0Context
  );

  // React.useEffect(() => {
  //   alert(isAuthenticated);
  // }, []);

  const login = () => {
    loginWithRedirect();
  };

  return (
    <>
      {!isLoading && !isAuthenticated && (
        <div style={{ marginTop: "50vh", marginLeft: "43vw" }}>
          <Button
            tag="label"
            className="btn-simple"
            color="info"
            size="lg"
            onClick={loginWithRedirect}
          >
            Login Here
          </Button>

          {/* <button onClick={loginWithRedirect} className="button is-danger">
            Login here
          </button> */}
        </div>
      )}
      {isAuthenticated && (
        <Router history={hist}>
          <Switch>
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
            } />
            <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
            <Redirect from="/" to="/admin/dashboard" />
          </Switch>
        </Router>
      )}
    </>
  );
};

export default App;
