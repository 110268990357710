import React, { Component } from "react";
import moment from "moment";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import api from "helpers/api";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import { MDBDataTable } from "mdbreact";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          top: "calc(25%)",
          display: "flex",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          transition: "opacity 0.3s",

          zIndex: "99999999999",
          opacity: ".8",
          // backgroundColor: "#eaeaea"
        }}
      >
        <Loader type="Oval" color="#1d8cf8" height={100} width={100} />
      </div>
    )
  );
};

export default class Feedback extends Component {
  state = {
    storeAllFeedbacks: [],
  };

  customizeData(data) {
    var parseddata = data.map((e) => {
      const param = e;
      const fmtteddate = moment(e.created_at).format("DD MMM, YYYY");
      param["fmtteddate"] = fmtteddate;
      return param;
    });
    this.setState({ storeAllFeedbacks: parseddata });
  }

  async componentDidMount() {
    const allFeedbacks = await trackPromise(api.get().getAllFeedbacks());
    if (allFeedbacks.status === 200) {
      if (allFeedbacks.data.status === "success") {
        this.customizeData(allFeedbacks.data.data);
      }
    }
  }
  render() {
    return (
      <>
        <LoadingIndicator />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">User Activities</h5>
                </CardHeader>

                <CardBody>
                  <MDBDataTable
                    striped
                    // bordered
                    hover
                    noBottomColumns
                    infoLabel={["Showing", "to", "of", "entries"]}
                    theadColor="indigo"
                    data={{
                      columns: [
                        {
                          label: "uuid",
                          field: "uuid",
                          // sort: "asc",
                          width: 150,
                        },
                        {
                          label: "Date Created",
                          field: "fmtteddate",
                          sort: "desc",
                          width: 150,
                        },
                        {
                          label: "Description",
                          field: "description",
                          width: 200,
                        },
                        {
                          label: "Rating",
                          field: "rating",
                          sort: "asc",
                          width: 100,
                        },
                      ],
                      rows: this.state.storeAllFeedbacks,
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
