import React from "react";
import lodash from "lodash";
import Moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { extendMoment } from "moment-range";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  // Table
  // CardFooter,
  // CardText,
  // Button,
  // FormGroup,
  // Form,
  // Input,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import api from "helpers/api";
import { getHoursPerMonth } from "./Dashboard";
import { Link } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

import { trackPromise } from "react-promise-tracker";
import { object } from "prop-types";

const moment = extendMoment(Moment);

export const filterEvents = (data, uuid) => {
  const singleUser = data.filter((event) => event.uuid === uuid);
  return singleUser;
};

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          top: "calc(25%)",
          display: "flex",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          transition: "opacity 0.3s",

          zIndex: "99999999999",
          opacity: ".8",
          // backgroundColor: "#eaeaea"
        }}
      >
        <Loader type="Oval" color="#1d8cf8" height={100} width={100} />
      </div>
    )
  );
};

class UserProfile extends React.Component {
  state = {
    storeAllUsers: [],
    storeAllEvents: [],
  };

  calculateTotalEventsPerUser = (uuid) => {
    const len = this.state.storeAllEvents.filter(
      (event) => event.uuid === uuid
    );
    // console.log(this.state.storeAllEvents);
    return len.length;
  };

  calculateTrackedHoursPerUser(uuid) {
    const user_events = this.state.storeAllEvents.filter(
      (event) => event.uuid === uuid
    );
    return getHoursPerMonth(user_events);
  }

  calculateDaysUsedPerUser(uuid) {
    const user_events = this.state.storeAllEvents.filter(
      (event) => event.uuid === uuid
    );
    const groupedDays = lodash.groupBy(user_events, function (value) {
      return moment(value.time_schedule.start_time).format("YYYY, MM, DD");
    });
    // console.log(lodash.size(groupedDays));
    return lodash.size(groupedDays);
  }

  calculateActiveWeeksPerUser = (uuid) => {
    const user_events = this.state.storeAllEvents.filter(
      (event) => event.uuid === uuid
    );
    const groupedWeek = lodash.groupBy(user_events, function (value) {
      return moment(value.time_schedule.start_time).format("YYYY, ww");
    });
    // console.log(lodash.size(groupedWeek));
    return lodash.size(groupedWeek);
  };

  calculateAverageActiveDays(uuid, join_date) {
    // const today = moment();
    // const joinedDate = moment(join_date);
    // const getWeeks = today.diff(joinedDate, "weeks");
    // const result = Math.ceil(
    //   this.calculateDaysUsedPerUser(uuid) /
    //     this.calculateActiveWeeksPerUser(uuid)
    // );
    const result = Math.ceil(
      this.calculateTotalEventsPerUser(uuid) /
        this.calculateDaysUsedPerUser(uuid)
    );
    // console.log(this.calculateActiveWeeksPerUser(uuid));
    if (this.calculateDaysUsedPerUser(uuid) === 0) {
      return 0;
    }
    return result;
  }

  calculateGapDays = (uuid, join_date, index) => {
    let arrayTotal = 0;
    const filteredData = filterEvents(this.state.storeAllEvents, uuid);
    const start = moment(join_date).format();
    const end = moment().format();
    const range = moment.range(start, end);
    const array = Array.from(range.by("days")); /*days, hours, years, etc.*/
    const alldays = array.map((m) => m.format("YYYY-MM-DD"));
    const existingEvents = filteredData.map((event) =>
      moment(event.created_on).format("YYYY-MM-DD")
    );
    // if (index === 0) {
    //   console.log("All Days", alldays);
    //   console.log("existing events", existingEvents);
    // }
    alldays.forEach((day) => {
      // console.log(alldays.includes("2020-04-26"));
      if (existingEvents.includes(day) === false) {
        arrayTotal++;
      }
    });
    // console.log(arrayTotal);

    return arrayTotal;
  };

  handleTableArrangements(data) {
    const customizedTable = [];
    data
      .sort(
        (a, b) =>
          this.calculateTotalEventsPerUser(b.uuid) -
          this.calculateTotalEventsPerUser(a.uuid)
      )
      .forEach((user, index) => {
        const userObj = {
          show: (
            <Link
              to={{
                pathname: `/admin/users/${user.uuid}`,
                state: {
                  userEvents: filterEvents(
                    this.state.storeAllEvents,
                    user.uuid
                  ),
                },
              }}
            >
              <i className="tim-icons icon-tap-02" /> Show
            </Link>
          ),
          uuid: user.uuid,
          join_date: moment(user.join_date).format("DD MMM, YYYY"),
          total_events: this.calculateTotalEventsPerUser(user.uuid),
          hours_tracked: Math.ceil(
            this.calculateTrackedHoursPerUser(user.uuid)
          ),
          avg_active_days: this.calculateAverageActiveDays(
            user.uuid,
            user.join_date
          ),
          days_used: this.calculateDaysUsedPerUser(user.uuid),
          gap_days: this.calculateGapDays(user.uuid, user.join_date, index),
        };
        customizedTable.push(userObj);
      });
    // console.log(customizedTable);
    this.setState({ customizedTable });
  }

  async componentDidMount() {
    const [fetchEvents, fetchUsers] = await Promise.all([
      trackPromise(api.get("api/v1/admin/fetch-all-events").getAll()),
      trackPromise(api.get("api/v1/admin/fetch-all-users").getAll()),
    ]);
    if (fetchEvents.status === 200) {
      if (fetchEvents.data.status === "success") {
        // console.log(fetchEvents.data.data);
        this.setState({ storeAllEvents: fetchEvents.data.data });
      }
    }
    if (fetchUsers.status === 200) {
      if (fetchUsers.data.status === "success") {
        this.handleTableArrangements(fetchUsers.data.data);
        this.setState({ storeAllUsers: fetchUsers.data.data });
      }
    }
  }

  render() {
    return (
      <>
        <LoadingIndicator />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">User Activities</h5>
                </CardHeader>

                <CardBody>
                  <MDBDataTable
                    striped
                    // bordered
                    hover
                    noBottomColumns
                    data={{
                      columns: [
                        {
                          label: "uuid",
                          field: "uuid",
                          // sort: "asc",
                          width: 150,
                        },
                        {
                          label: "Join Date",
                          field: "join_date",
                          sort: "desc",
                          width: 270,
                        },
                        {
                          label: "Total Events",
                          field: "total_events",
                          // sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Hours Tracked",
                          field: "hours_tracked",
                          // sort: "asc",
                          width: 100,
                        },
                        {
                          label: "Avg. Entries/Active Day",
                          field: "avg_active_days",
                          // sort: "asc",
                          width: 150,
                        },
                        {
                          label: "Days Used",
                          field: "days_used",
                          // sort: "asc",
                          width: 150,
                        },
                        {
                          label: "Gap Days",
                          field: "gap_days",
                          // sort: "asc",
                          width: 100,
                        },
                        {
                          label: "",
                          field: "show",
                          // sort: "asc",
                          // width: 150,
                        },
                      ],
                      rows: this.state.customizedTable,
                    }}
                  />
                </CardBody>

                {/* <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                </CardFooter> */}
              </Card>
            </Col>
            {/* <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar"
                        src={require("assets/img/emilyz.jpg")}
                      />
                      <h5 className="title">Mike Andrew</h5>
                    </a>
                    <p className="description">Ceo/Co-Founder</p>
                  </div>
                  <div className="card-description">
                    Do not be scared of the truth because we need to restart the
                    human foundation in truth And I love you like Kanye loves
                    Kanye I love Rick Owens’ bed design but the back is...
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="button-container">
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fab fa-facebook" />
                    </Button>
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button className="btn-icon btn-round" color="google">
                      <i className="fab fa-google-plus" />
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col> */}
          </Row>
        </div>
      </>
    );
  }
}

export default UserProfile;
